

export class HttpAssets {
    private readonly headers : Headers;
    private readonly urlParams : URLSearchParams;


    constructor(headers: Headers, urlParams: URLSearchParams) {
        this.headers = headers;
        this.urlParams = urlParams;
    }

    getHeaders(): Headers {
        return this.headers;
    }

    getUrlParams() : URLSearchParams {
        return this.urlParams;
    }
}