<template>
    <v-sheet class="d-flex flex-row justify-start">
        <navigation-drawer-container-component selected-value="api">
            <v-container class="pa-0 ma-0">
                <div class="ma-2 d-flex justify-center">
                    <v-btn color="primary" variant="outlined" @click="reload()">
                        <v-icon>mdi-reload</v-icon>
                        Refresh
                    </v-btn>
                </div>
                <v-table>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Version
                        </th>
                        <th class="text-left">
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="api in ui.apiInfos"
                        :key="api.name"
                    >
                        <td>{{ api.name }}</td>
                        <td>{{ api.version }}</td>
                        <td>
                            <v-chip :color="api.isOnline ? 'green' : 'red'">{{
                                    api.isOnline ? 'online' : 'down'
                                }}
                            </v-chip>
                        </td>
                    </tr>
                    </tbody>
                </v-table>
            </v-container>
        </navigation-drawer-container-component>
    </v-sheet>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavigationDrawerContainerComponent from "@/components/business/NavigationDrawerContainerComponent.vue";
import {ApiInfos} from "@/models/ApiInfos";
import {Context} from "@/Context";

@Component({
    components: {NavigationDrawerContainerComponent}
})
export default class ApiView extends Vue {

    private readonly service = Context.provideApiService();

    private readonly ui = {
        apiInfos: [] as ApiInfos[]
    }

    async mounted() {
        this.loadUi().catch((e) => {
            console.error(e);
        });
    }

    private async loadUi() {
        this.ui.apiInfos = await this.service.getAllApiInfos();
    }

    private async reload(){
        await this.loadUi();
    }

}
</script>

<style scoped>

</style>