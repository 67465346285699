<template>
    <v-container class="w-50 mt-16 container">
        <img class="ma-6" src="@/assets/img/iaca-logo.png" alt="Logo IACA Electronique">
        <v-card class="elevation-4 w-100">
            <v-card-title>
                Authentification
            </v-card-title>
            <v-text-field
                class="ma-2"
                v-model="ui.ip"
                label="Adresse IP cible"
            />
            <v-text-field class="ma-2"
                          v-model="ui.password.value"
                          label="Clé de connexion"
                          :type="ui.password.show ? 'text' : 'password'"
                          :append-icon="ui.password.show ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="ui.password.show = !ui.password.show"
                          @keydown.enter="login()"
            />
            <v-card-actions>
                <v-btn @click="login()" color="primary" variant="flat" block>Connexion</v-btn>
            </v-card-actions>

            <v-card-actions>
                <v-btn @click="clearUi()" color="grey" variant="outlined" block>Vider</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
    <error-dialog-component
        :showing="errorDialog.show"
        :title="errorDialog.title"
        :message="errorDialog.message"
        @on-close="() => errorDialog.show = false"></error-dialog-component>
</template>

<script lang="ts">


import {Context} from "@/Context";
import {TokenService} from "@/services/TokenService";
import Session from "@/Session";

import {Component, Vue} from "vue-facing-decorator";
import ErrorDialogComponent from "@/components/common/ErrorDialogComponent.vue";

@Component({
    components: {ErrorDialogComponent}
})
export default class LoginView extends Vue {
    private readonly ui = {
        password : {
            value : '',
            show : false
        },
        ip : ''
    }

    private errorDialog = {
        title: 'Erreur',
        message: '',
        show: false
    };

    private async login(): Promise<void> {
        const service: TokenService = Context.provideTokenServiceFromIp(this.ui.ip);
        try {
            const isValid = await service.isValid(this.ui.password.value);
            if (isValid) {
                Session.setIp(this.ui.ip);
                Session.setToken(this.ui.password.value);
                this.$router.push({name: 'home'});
            }else {
                this.showError(new Error('Bad API Key'));
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error)
                this.showError(e);
        }
    }

    private clearUi(){
        this.ui.password.value = '';
        this.ui.ip = '';
    }

    private showError(e: Error) {
        this.errorDialog.message = e.message;
        this.errorDialog.show = true;
    }
}
</script>

<style scoped>

img {
    height: 100px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;
    min-width: 500px;
}
</style>