const JWT = 'JWT';
const IP = 'IP';
export default class Session {


    static hasToken(): boolean {
        return localStorage.getItem(JWT) != null;
    }

    static setToken(token: string): void {
        localStorage.setItem(JWT, token);
    }

    static getToken(): string {
        return localStorage.getItem(JWT)!;
    }

    static resetToken(): void {
        localStorage.removeItem(JWT);
    }

    static setIp(ip: string): void {
        localStorage.setItem(IP, ip);
    }

    static getIp(): string {
        return localStorage.getItem(IP)!;
    }
}