import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_command_field_component = _resolveComponent("command-field-component")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_command_result_component = _resolveComponent("command-result-component")!
  const _component_navigation_drawer_component = _resolveComponent("navigation-drawer-component")!

  return (_openBlock(), _createBlock(_component_v_sheet, { class: "d-flex flex-row justify-start" }, {
    default: _withCtx(() => [
      _createVNode(_component_navigation_drawer_component, {
        "selected-value": "shell",
        "prevent-closing": true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_command_field_component, {
            onCommandSent: _cache[0] || (_cache[0] = (cmd) => _ctx.commands.unshift(cmd))
          }),
          _createVNode(_component_v_sheet, { class: "w-100 d-flex align-center justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                color: "grey",
                variant: "outlined",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearAllCommands()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("CLEAR ALL")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commands, (c) => {
            return (_openBlock(), _createBlock(_component_command_result_component, {
              key: c,
              command: c
            }, null, 8, ["command"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}