<template>
    <v-sheet class="d-flex flex-row justify-start">
        <navigation-drawer-component selected-value="dashboard">
            <v-card class="ma-2" width="50%">
                <v-card-title>Informations</v-card-title>
                <v-card-text>
                    <div class="simple-invisible-table-2-columns">
                        <div>UUID :</div><v-chip color="primary">{{ ui.uuid }}</v-chip>
                        <div>OS :</div><v-chip color="primary">{{ ui.osVersion }}</v-chip>
                    </div>
                </v-card-text>
            </v-card>
        </navigation-drawer-component>
    </v-sheet>
</template>

<script lang="ts">


import {Component, Vue} from "vue-facing-decorator";
import NavigationDrawerContainerComponent from "@/components/business/NavigationDrawerContainerComponent.vue";
import {InfosService} from "@/services/InfosService";
import {Context} from "@/Context";

@Component({
    components: {NavigationDrawerComponent: NavigationDrawerContainerComponent}
})

export default class HomeView extends Vue {
    private readonly infosService: InfosService = Context.provideInfosService();

    private readonly ui = {
        osVersion: '',
        uuid: ''
    }

    mounted(): void {
        this.loadUi().catch((e) => {
            console.error(e);
        });
    }

    private async loadUi(): Promise<void> {
        this.ui.osVersion = await this.infosService.getOSVersion();
        this.ui.uuid = await this.infosService.getUUID();
    }

}
</script>

<style scoped>

.simple-invisible-table-2-columns {
    display: grid;
    grid-template-columns: 50px auto;
    grid-gap: 10px;
    align-items: center;
    justify-items: start;
}

</style>