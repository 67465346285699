<template>
    <v-app>
        <v-container class="pa-0 ma-0 navigation-app-skeleton">
            <v-navigation-drawer
                class="ma-0 pa-0"
                :width="200"
                permanent
                floating
                color="primary">

                <v-list
                    density="compact"
                    nav
                >
                    <v-list-item
                        title="Settings"
                        subtitle="IACA"/>

                    <v-divider/>

                    <v-list-item
                        link
                        prepend-icon="mdi-view-dashboard"
                        title="Dashboard"
                        @click="navigateTo('home')"
                        :class="{selected : selectedValue === 'dashboard'}"
                    />

                    <v-list-item
                        link
                        prepend-icon="mdi-information"
                        title="Services"
                        @click="navigateTo('services')"
                        :class="{selected : selectedValue === 'services'}"
                    />

                    <v-list-item
                        link
                        prepend-icon="mdi-api"
                        title="APIs"
                        @click="navigateTo('api')"
                        :class="{selected : selectedValue === 'api'}"
                    />

                    <v-list-item
                        link
                        prepend-icon="mdi-console-line"
                        title="Shell"
                        @click="navigateTo('shell')"
                        :class="{selected : selectedValue === 'shell'}"
                    />

                    <v-list-item
                        link
                        prepend-icon="mdi-power"
                        title="Power"
                        @click="navigateTo('power')"
                        :class="{selected : selectedValue === 'power'}"
                    />
                </v-list>
            </v-navigation-drawer>
            <v-container class="ma-0 pa-0 h-100 main-display">
                <slot></slot>
            </v-container>

        </v-container>
    </v-app>

    <confirm-dialog-component
        :show="ui.confirmDialog.show"
        title="Closing confirmation"
        message="This page will be changed and some data can be lost, are you sure to quit this page ?"
        @on-confirm="ui.confirmDialog.onConfirm()"
        @on-cancel="ui.confirmDialog.show = false;"
    />

</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import ConfirmDialogComponent from "@/components/common/ConfirmDialogComponent.vue";

@Component({
    components: {ConfirmDialogComponent}
})
export default class NavigationDrawerContainerComponent extends Vue {

    @Prop
    private selectedValue?: string;

    @Prop
    private preventClosing?: boolean;

    private readonly ui = {
        confirmDialog: {
            show: false,
            onConfirm: () => {
            }
        }
    }

    // ---------------------------------------------------------------------------------------------------------------

    private navigateTo(target: string) {
        if (target !== this.selectedValue)
            if (this.preventClosing === true) {
                this.ui.confirmDialog.onConfirm = () => this.$router.push({name: target});
                this.ui.confirmDialog.show = true;
            } else {
                this.$router.push({name: target});
            }
    }

}
</script>

<style scoped>

.navigation-app-skeleton {
    display: grid;
    grid-template-columns: 200px calc(100vw - 200px);
    width: 100vw;
    height: 100vh;
}

.main-display {
    grid-column-start: 2;
    width: 100%;
}

.selected {
    background-color: rgba(255, 255, 255, 0.3);
}

</style>