<template>
    <v-dialog
        v-model="this.showing"
        persistent
        width="460px"
    >
        <v-card>
            <v-card-title>Power management</v-card-title>
            <v-card-text>Your are about to do power action on card.</v-card-text>
            <v-card-text v-if="ui.commandSent">
                <v-chip color="green">
                    <v-icon>mdi-check</v-icon>
                    Command sent
                </v-chip>
            </v-card-text>
            <v-card-text v-if="ui.progress.working">
                Waiting for establishing (take few times)..
                <v-progress-linear :model-value="ui.progress.value"></v-progress-linear>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :disabled="!ui.buttons.enable"
                    @click="$emit('onCancel')"
                >
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="deep-orange-darken-4"
                    :disabled="!ui.buttons.enable"
                    @click="this.startAction()">{{ ui.buttons.action }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <error-dialog-component
        title="Error"
        :message="ui.errorDialog.message"
        :showing="ui.errorDialog.showing"
        @on-close="ui.errorDialog.showing = false"
        />

</template>

<script lang="ts">
import {Vue, Component, Prop, Watch, Emit} from 'vue-facing-decorator';
import {isNotUndefined} from "@/utils/Types";
import {sleep} from "@/utils/Sleep";
import {PowerService} from "@/services/PowerService";
import {Context} from "@/Context";
import ErrorDialogComponent from "@/components/common/ErrorDialogComponent.vue";

@Component({
    components: {ErrorDialogComponent}
})
export default class PowerActionDialogComponent extends Vue {

    @Prop
    showing?: boolean;

    @Prop
    state?: string;

    @Emit
    onCancel() {

    }

    @Watch('state')
    private onStateChanged(value: string) {
        this.loadUi();
    }


    // ---------------------------------------------------------------------------------------------------------------

    private readonly service : PowerService = Context.providePowerService();

    private readonly ui = {
        buttons: {
            action: '',
            enable: true
        },
        progress: {
            value : 0,
            working : false
        },
        commandSent : false,
        errorDialog:{
            message : '',
            showing: false
        }
    }

    // ---------------------------------------------------------------------------------------------------------------


    mounted() {
        this.loadUi();
    }


    private loadUi() {
        if (this.configurationIsConsistent()) {
            if (this.isReboot()) {
                this.ui.buttons.action = 'Reboot';
            } else {
                this.ui.buttons.action = 'Poweroff';
            }
        } else {
            console.error("Bad component usage (power action dialog component).");
        }
    }

    private configurationIsConsistent() {
        return isNotUndefined(this.state) && (this.state === 'poweroff' || this.state === 'reboot');
    }

    private isReboot() {
        return this.state === 'reboot';
    }

    private async startAction() {
        try{
            if (this.isReboot()) {
                await this.service.reboot();
            } else {
                await this.service.poweroff();
            }

            this.setUiToWaitingMode();
            this.waitUntilActionFinish();

        }catch (e){
            console.error(e);
            if(e instanceof Error)
                this.showError(e.message);
        }

    }

    private setUiToWaitingMode() {
        this.ui.commandSent = true;
        this.ui.progress.value = 0;
        this.ui.progress.working = true;
        this.ui.buttons.enable = false;
    }

    private async waitUntilActionFinish() {
        await this.simulateProgress(60);

        this.ui.buttons.enable = true;

        this.$router.push({name:'home'});

    }

    private async simulateProgress(time : number) {
        for (let i = 1; i <= time; i++) {
            await sleep(1)
            this.ui.progress.value = (i / time) * 100;
        }
    }

    private showError(message : string){
        this.ui.errorDialog.message = message;
        this.ui.errorDialog.showing = true;
    }
}
</script>


<style scoped>

</style>