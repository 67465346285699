import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import {Context} from "@/Context";
import LoginView from "@/views/LoginView.vue";
import HomeView from "@/views/HomeView.vue";
import ServicesView from "@/views/ServicesView.vue";
import PowerView from "@/views/PowerView.vue";
import ApiView from "@/views/ApiView.vue";
import ShellView from "@/views/ShellView.vue";


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            security: true
        }
    },
    {
        path: "/service",
        name: "services",
        component: ServicesView,
        meta: {
            security: true
        }
    },
    {
        path: "/power",
        name: "power",
        component: PowerView,
        meta: {
            security: true
        }
    },
    {
        path: "/shell",
        name: "shell",
        component: ShellView,
        meta: {
            security: true
        }
    },
    {
        path: "/api",
        name: "api",
        component: ApiView,
        meta: {
            security: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: LoginView
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

Context.setRouter(router);

const securityMiddleware = Context.provideSecurityAppMiddleware();
const validityAppMiddleware = Context.provideValidityAppMiddleware();

router.beforeEach((to, from) => {
    if (typeof to.meta.security === 'boolean' && to.meta.security) {
        securityMiddleware.load().then().catch(reason => {
            console.error('Unable to execute security middleware.', reason);
        });
        validityAppMiddleware.load().then().catch(reason => {
            console.error('Unable to execute validity middleware.', reason);
        });
    }
});


export default router;
