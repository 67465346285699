import {HttpAssets} from "@/services/assets/HttpAssets";
import {HttpAssetsBuilderImpl} from "@/services/assets/builders/HttpAssetsBuilderImpl";
import Session from "@/Session";
import {SecurityAppMiddleware} from "@/middlewares/SecurityAppMiddleware";
import {Router} from "vue-router";
import {ValidityAppMiddleware} from "@/middlewares/ValidityAppMiddleware";
import {TokenService} from "@/services/TokenService";
import {TokenServiceImpl} from "@/services/TokenServiceImpl";
import getEnv from "@/utils/Environment";
import {InfosService} from "@/services/InfosService";
import {isUndefined} from "@/utils/Types";
import {InfosServiceImpl} from "@/services/InfosServiceImpl";
import {ApiService} from "@/services/ApiService";
import {ApiServiceImpl} from "@/services/ApiServiceImpl";
import {PowerService} from "@/services/PowerService";
import {PowerServiceImpl} from "@/services/PowerServiceImpl";
import {ShellService} from "@/services/ShellService";
import {ShellServiceImpl} from "@/services/ShellServiceImpl";

export class Context {
    private static authentificationApiUrl: string = Context.getApiUrlFromPort(getEnv("VUE_APP_AUTHENTIFICATION_API_PORT")!);
    private static powerApiUrl: string = Context.getApiUrlFromPort(getEnv("VUE_APP_POWER_API_PORT")!);
    private static router?: Router;

    private static apiService: ApiService;
    private static powerService: PowerService;

    static setRouter(router: Router) {
        this.router = router;
    }

    public static provideSecurityAppMiddleware(): SecurityAppMiddleware {
        return new SecurityAppMiddleware(this.router!);
    }

    public static provideValidityAppMiddleware(): ValidityAppMiddleware {
        return new ValidityAppMiddleware(this.provideTokenService(), this.router!);
    }


    public static provideTokenService(): TokenService {
        return new TokenServiceImpl(Context.getApiUrlFromPort(getEnv("VUE_APP_AUTHENTIFICATION_API_PORT")!));
    }

    public static provideTokenServiceFromIp(ip: string): TokenService {
        return new TokenServiceImpl(Context.getApiUrlFromIpAndPort(ip, getEnv("VUE_APP_AUTHENTIFICATION_API_PORT")!));
    }

    public static provideInfosService(): InfosService {
        return new InfosServiceImpl(Context.getApiUrlFromPort(getEnv("VUE_APP_INFOS_API_PORT")!));
    }

    public static provideShellService(): ShellService {
        return new ShellServiceImpl(Context.getApiUrlFromPort(getEnv("VUE_APP_SHELL_API_PORT")!), this.createHttpAssets());
    }

    public static provideApiService(): ApiService {
        if (isUndefined(this.apiService))
            this.apiService = new ApiServiceImpl([
                {
                    url: Context.getApiUrlFromPort(getEnv("VUE_APP_INFOS_API_PORT")!),
                    name: 'core-infos'
                }, {
                    url: this.authentificationApiUrl,
                    name: 'core-security'
                },
                {
                    url: Context.getApiUrlFromPort(getEnv("VUE_APP_SHELL_API_PORT")!),
                    name: 'core-shell'
                }]);

        return this.apiService;
    }

    public static providePowerService(): PowerService {
        if (isUndefined(this.powerService))
            this.powerService = new PowerServiceImpl(this.powerApiUrl, this.createHttpAssets());
        return this.powerService;
    }

    private static createHttpAssets(): HttpAssets {
        const builder = HttpAssetsBuilderImpl.create();
        if (!Session.hasToken()) console.warn(new Error(`No token in session !`));
        else builder.setToken(Session.getToken());
        return builder.build();
    }

    private static getApiUrlFromPort(port: string): string {
        return this.getApiUrlFromIpAndPort(Session.getIp(), port);
    }

    private static getApiUrlFromIpAndPort(ip: string, port: string): string {
        return `${getEnv("VUE_APP_PROTOCOL")!}://${ip}:${port}`;
    }
}