import {TokenService} from "@/services/TokenService";
import {HttpAssetsBuilderImpl} from "@/services/assets/builders/HttpAssetsBuilderImpl";

export class TokenServiceImpl implements TokenService {

    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    async isValid(key: string): Promise<boolean> {
        const builder = HttpAssetsBuilderImpl.create();
        builder.setToken(key);

        const response = await fetch(`${this.apiUrl}/api/v1/security/key/check`,
            {
                headers: builder.build().getHeaders()
            });

        return response.status === 200;
    }
}