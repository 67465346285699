import {ShellService} from "@/services/ShellService";
import {HttpAssets} from "@/services/assets/HttpAssets";
import {checkResponseStatusIs200} from "@/services/utils/ResponseUtils";


export class ShellServiceImpl implements ShellService {

    private readonly apiUrl: string;
    private readonly httpAssets: HttpAssets;

    // ---------------------------------------------------------------------------------------------------------------
    constructor(apiUrl: string, httpAssets: HttpAssets) {
        this.apiUrl = apiUrl;
        this.httpAssets = httpAssets;
    }

    // ---------------------------------------------------------------------------------------------------------------

    public async runCmd(cmd: string): Promise<string> {
        const payload = {
            cmd: cmd
        };

        const headers = new Headers();

        this.httpAssets.getHeaders().forEach((value, key) => headers.set(key, value));
        headers.set("content-type", "application/json");

        const response = await fetch(`${this.apiUrl}/api/v1/ssh/cmd`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload),
        });

        checkResponseStatusIs200(response);

        return response.text();
    }


}