import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app = _resolveComponent("v-app")!
  const _component_confirm_dialog_component = _resolveComponent("confirm-dialog-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, { class: "pa-0 ma-0 navigation-app-skeleton" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_navigation_drawer, {
              class: "ma-0 pa-0",
              width: 200,
              permanent: "",
              floating: "",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, {
                  density: "compact",
                  nav: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, {
                      title: "Settings",
                      subtitle: "IACA"
                    }),
                    _createVNode(_component_v_divider),
                    _createVNode(_component_v_list_item, {
                      link: "",
                      "prepend-icon": "mdi-view-dashboard",
                      title: "Dashboard",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo('home'))),
                      class: _normalizeClass({selected : _ctx.selectedValue === 'dashboard'})
                    }, null, 8, ["class"]),
                    _createVNode(_component_v_list_item, {
                      link: "",
                      "prepend-icon": "mdi-information",
                      title: "Services",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateTo('services'))),
                      class: _normalizeClass({selected : _ctx.selectedValue === 'services'})
                    }, null, 8, ["class"]),
                    _createVNode(_component_v_list_item, {
                      link: "",
                      "prepend-icon": "mdi-api",
                      title: "APIs",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigateTo('api'))),
                      class: _normalizeClass({selected : _ctx.selectedValue === 'api'})
                    }, null, 8, ["class"]),
                    _createVNode(_component_v_list_item, {
                      link: "",
                      "prepend-icon": "mdi-console-line",
                      title: "Shell",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigateTo('shell'))),
                      class: _normalizeClass({selected : _ctx.selectedValue === 'shell'})
                    }, null, 8, ["class"]),
                    _createVNode(_component_v_list_item, {
                      link: "",
                      "prepend-icon": "mdi-power",
                      title: "Power",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigateTo('power'))),
                      class: _normalizeClass({selected : _ctx.selectedValue === 'power'})
                    }, null, 8, ["class"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_container, { class: "ma-0 pa-0 h-100 main-display" }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    _createVNode(_component_confirm_dialog_component, {
      show: _ctx.ui.confirmDialog.show,
      title: "Closing confirmation",
      message: "This page will be changed and some data can be lost, are you sure to quit this page ?",
      onOnConfirm: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ui.confirmDialog.onConfirm())),
      onOnCancel: _cache[6] || (_cache[6] = ($event: any) => {_ctx.ui.confirmDialog.show = false;})
    }, null, 8, ["show"])
  ], 64))
}