<template>
    <v-card :color="!ui.result.isLoading && !ui.error.show ? 'green' : 'orange'" variant="outlined" class="pa-2 ma-5">
        <v-card-title>
            <div class="w-100 d-flex justify-end position-absolute command-time-container">
                <div class="command-time">{{ ui.date }}</div>
            </div>
            <v-progress-circular
                v-if="ui.result.isLoading"
                color="primary"
                size="20"
                indeterminate/>
            <v-icon v-if="!ui.result.isLoading && !ui.error.show"
                    size="20"
                    color="green">mdi-check-circle
            </v-icon>

            <v-icon v-if="!ui.result.isLoading && ui.error.show"
                    size="20"
                    color="red">mdi-alert-circle
            </v-icon>

            {{ command }}
        </v-card-title>
        <v-alert v-if="ui.error.show" type="error" title="Error" :text="ui.error.cause"></v-alert>
        <v-card-text v-if="ui.result.value !== ''" class="console">{{ ui.result.value }}</v-card-text>
    </v-card>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-facing-decorator';
import {Context} from "@/Context";
import {isNotUndefined} from "@/utils/Types";
import {getPrettyActualTime} from "@/utils/DateUtils";

@Component({})
export default class CommandResultComponent extends Vue {

    private readonly service = Context.provideShellService();

    @Prop
    private command?: string;

    private ui = {
        result: {
            value: '',
            isLoading: false
        },
        error: {
            show: false,
            cause: ''
        },
        date: getPrettyActualTime()
    }

    async mounted() {
        if (isNotUndefined(this.command)) {
            try {
                this.ui.result.isLoading = true;
                this.ui.result.value = await this.service.runCmd(this.command!);
            } catch (e) {
                console.error(e);
                if (e instanceof Error)
                    this.showError(e.message);
            } finally {
                this.ui.result.isLoading = false;
            }
        }
    }

    private showError(error: string) {
        this.ui.error.show = true;
        this.ui.error.cause = error;
    }

}
</script>

<style scoped>

.console {
    color: white;
    background-color: rgba(51, 51, 51, 0.8);
    font-family: monospace;
    overflow-y: scroll;
    white-space: pre-wrap;

    max-height: 300px;
}

.command-time-container {
    transform: translateY(-15px);
}

.command-time {
    margin-right: 50px;
    font-size: 12px;
    font-style: italic;

    color: grey;
}
</style>