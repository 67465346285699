import {PowerService} from "@/services/PowerService";
import {HttpAssets} from "@/services/assets/HttpAssets";
import {checkResponseStatusIs200} from "@/services/utils/ResponseUtils";


export class PowerServiceImpl implements PowerService {

    private readonly apiUrl: string;
    private readonly httpAssets : HttpAssets;

    // ---------------------------------------------------------------------------------------------------------------
    constructor(apiUrl: string, httpAssets: HttpAssets) {
        this.apiUrl = apiUrl;
        this.httpAssets = httpAssets;
    }

    // ---------------------------------------------------------------------------------------------------------------

    public async poweroff(): Promise<void> {
        const response = await fetch(`${this.apiUrl}/api/v1/power/poweroff`,{
            method: 'POST',
            headers : this.httpAssets.getHeaders()
        });

        checkResponseStatusIs200(response);
    }

    public async reboot(): Promise<void> {
        const response = await fetch(`${this.apiUrl}/api/v1/power/reboot`,{
            method: 'POST',
            headers : this.httpAssets.getHeaders()
        });

        checkResponseStatusIs200(response);
    }



}