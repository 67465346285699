import {ApiService} from "@/services/ApiService";
import {ApiInfos} from "@/models/ApiInfos";
import {ApiSettings} from "@/models/ApiSettings";
import {ApiVersionDto} from "@/services/dto/ApiVersionDto";

export class ApiServiceImpl implements ApiService {

    private readonly apiSettings: ApiSettings[];

    // ---------------------------------------------------------------------------------------------------------------

    constructor(apiSettings: ApiSettings[]) {
        this.apiSettings = apiSettings;
    }

    // ---------------------------------------------------------------------------------------------------------------

    public async getAllApiInfos(): Promise<ApiInfos[]> {
        const api = [] as ApiInfos[];

        for (let apiSetting of this.apiSettings) {
            let isOnline: boolean;
            let version: string;
            try {
                const response = await fetch(`${apiSetting.url}/api/v1/system/version`);
                const dto = await response.json() as ApiVersionDto;
                isOnline = true;
                version = dto.version;
            } catch (e) {
                isOnline = false;
                version = '-';
            }
            api.push({name: apiSetting.name, version: version, isOnline: isOnline})
        }


        return api;
    }


}