import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_navigation_drawer_component = _resolveComponent("navigation-drawer-component")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_power_action_dialog_component = _resolveComponent("power-action-dialog-component")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_sheet, { class: "d-flex flex-row justify-start" }, {
      default: _withCtx(() => [
        _createVNode(_component_navigation_drawer_component, { "selected-value": "power" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              class: "ma-2",
              width: "50%"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Power")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      color: "orange",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.showRebootDialog()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-reload")
                          ]),
                          _: 1
                        }),
                        _createTextVNode("REBOOT")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "red",
                      onClick: this.showPoweroffDialog
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-power")
                          ]),
                          _: 1
                        }),
                        _createTextVNode("POWEROFF")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_power_action_dialog_component, {
      showing: _ctx.ui.dialog.show,
      state: _ctx.ui.dialog.state,
      onOnCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ui.dialog.show = false))
    }, null, 8, ["showing", "state"])
  ], 64))
}