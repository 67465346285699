import {HttpAssetsBuilder} from "./HttpAssetsBuilder";
import {HttpAssets} from "../HttpAssets";

export class HttpAssetsBuilderImpl implements HttpAssetsBuilder {

    private token: string;


    private constructor() {
        this.token = "";
    }

    static create(): HttpAssetsBuilder {
        return new HttpAssetsBuilderImpl();
    }

    setToken(jwt: string): HttpAssetsBuilder {
        this.token = jwt;
        return this;
    }

    build(): HttpAssets {
        const headers = new Headers();
        headers.set('api-key', this.token);

        const urlSearchParams = new URLSearchParams();
        urlSearchParams.set('api-key', this.token);

        return new HttpAssets(headers, urlSearchParams);
    }

}