import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aa11964"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 d-flex justify-end position-absolute command-time-container" }
const _hoisted_2 = { class: "command-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: !_ctx.ui.result.isLoading && !_ctx.ui.error.show ? 'green' : 'orange',
    variant: "outlined",
    class: "pa-2 ma-5"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.ui.date), 1)
          ]),
          (_ctx.ui.result.isLoading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 0,
                color: "primary",
                size: "20",
                indeterminate: ""
              }))
            : _createCommentVNode("", true),
          (!_ctx.ui.result.isLoading && !_ctx.ui.error.show)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 1,
                size: "20",
                color: "green"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-check-circle ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.ui.result.isLoading && _ctx.ui.error.show)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 2,
                size: "20",
                color: "red"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-alert-circle ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.command), 1)
        ]),
        _: 1
      }),
      (_ctx.ui.error.show)
        ? (_openBlock(), _createBlock(_component_v_alert, {
            key: 0,
            type: "error",
            title: "Error",
            text: _ctx.ui.error.cause
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      (_ctx.ui.result.value !== '')
        ? (_openBlock(), _createBlock(_component_v_card_text, {
            key: 1,
            class: "console"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.ui.result.value), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["color"]))
}