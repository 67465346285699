<template>
    <v-card color="primary" variant="outlined" class="pa-2 ma-5">
        <v-text-field
            title="Command"
            @keydown.enter="$emit('command-sent', ui.command); ui.command = ''"
            v-model="ui.command"
            append-inner-icon="mdi-keyboard-return"
        />
    </v-card>
</template>

<script lang="ts">
import {Vue, Component, Emit} from 'vue-facing-decorator';

@Component({})
export default class CommandFieldComponent extends Vue {

    private readonly ui = {
        command : ''
    };

    @Emit()
    commandSent(cmd : string) {

    }
}
</script>

<style scoped>

</style>