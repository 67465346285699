<template>
    <v-dialog
        v-model="show"
        persistent
        width="460px"
    >
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>{{ message }}</v-card-text>
            <v-card-actions>
                <v-btn color="grey" @click="$emit('on-cancel')">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="deep-orange-darken-4" @click="$emit('on-confirm')">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script lang="ts">
import {Vue, Component, Prop, Emit} from 'vue-facing-decorator';

@Component({})
export default class ConfirmDialogComponent extends Vue {

    @Prop
    private show? : boolean;

    @Prop
    private title? : string;

    @Prop
    private message? : string;

    @Emit
    private onCancel(){

    }

    @Emit()
    private onConfirm(){

    }

}
</script>



<style scoped>

</style>