import {InfosService} from "@/services/InfosService";
import {checkResponseStatusIs200} from "@/services/utils/ResponseUtils";


export class InfosServiceImpl implements InfosService {

    private readonly apiUrl: string;

    // ---------------------------------------------------------------------------------------------------------------

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }


    // ---------------------------------------------------------------------------------------------------------------
    public async getOSVersion(): Promise<string> {
        const response = await fetch(`${this.apiUrl}/version`);

        checkResponseStatusIs200(response);

        const dto = await response.json() as { version: string }

        return dto.version;
    }

    public async getUUID(): Promise<string> {
        const response = await fetch(`${this.apiUrl}/uuid`);

        checkResponseStatusIs200(response);

        const dto = await response.json() as { uuid: string }

        return dto.uuid;
    }

}