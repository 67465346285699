<template>
    <v-sheet class="d-flex flex-row justify-start">
        <navigation-drawer-container-component selected-value="services" >
            <v-table>
                <thead>
                <tr>
                    <th class="text-left">
                        Name
                    </th>
                    <th class="text-left">
                        Calories
                    </th>
                </tr>
                </thead>
                <tbody>
                <!--            <tr-->
                <!--                v-for="item in desserts"-->
                <!--                :key="item.name"-->
                <!--            >-->
                <!--                <td>{{ item.name }}</td>-->
                <!--                <td>{{ item.calories }}</td>-->
                <!--            </tr>-->
                </tbody>
            </v-table>
        </navigation-drawer-container-component>
    </v-sheet>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavigationDrawerContainerComponent from "@/components/business/NavigationDrawerContainerComponent.vue";

@Component({
    components: {NavigationDrawerContainerComponent}
})
export default class ServicesView extends Vue {

}
</script>


<style scoped>

</style>