import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "primary",
    variant: "outlined",
    class: "pa-2 ma-5"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_text_field, {
        title: "Command",
        onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => {_ctx.$emit('command-sent', _ctx.ui.command); _ctx.ui.command = ''}, ["enter"])),
        modelValue: _ctx.ui.command,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ui.command) = $event)),
        "append-inner-icon": "mdi-keyboard-return"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}