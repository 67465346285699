<template>
    <v-sheet class="d-flex flex-row justify-start">
        <navigation-drawer-component selected-value="power" >
            <v-card class="ma-2" width="50%" >
                <v-card-title>Power</v-card-title>
                <v-card-actions>
                    <v-btn color="orange" @click="this.showRebootDialog()"><v-icon>mdi-reload</v-icon>REBOOT</v-btn>
                    <v-btn color="red" @click="this.showPoweroffDialog" ><v-icon>mdi-power</v-icon>POWEROFF</v-btn>
                </v-card-actions>
            </v-card>
        </navigation-drawer-component>
    </v-sheet>
    <power-action-dialog-component
        :showing="ui.dialog.show"
        :state="ui.dialog.state"
        @on-cancel="ui.dialog.show = false"
    />

</template>

<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavigationDrawerComponent from "@/components/business/NavigationDrawerContainerComponent.vue";
import PowerActionDialogComponent from "@/components/business/PowerActionDialogComponent.vue";

@Component({
    components: {PowerActionDialogComponent, NavigationDrawerComponent}
})
export default class PowerView extends Vue {

    private readonly ui = {
        dialog : {
            state : '',
            show : false
        }
    }

    private showRebootDialog(){
        this.ui.dialog.state = 'reboot';
        this.ui.dialog.show = true;
    }

    private showPoweroffDialog(){
        this.ui.dialog.state = 'poweroff';
        this.ui.dialog.show = true;
    }


}
</script>


<style scoped>

</style>