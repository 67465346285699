import Session from "@/Session";
import {Router} from "vue-router";


export class SecurityAppMiddleware {

    private readonly router: Router;


    constructor(router: Router) {
        this.router = router;
    }

    async load(): Promise<void> {
        if (Session.hasToken())
            console.log('JWT detected.');
        else {
            console.log("User not logged.")
            await this.router.push({name: 'login'});
        }

        return Promise.resolve();
    }

}