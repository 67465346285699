<template>
    <v-sheet class="d-flex flex-row justify-start">
        <navigation-drawer-component selected-value="shell" :prevent-closing="true" >
            <command-field-component
                @command-sent="(cmd : string) => commands.unshift(cmd)"
            />

            <v-sheet class="w-100 d-flex align-center justify-center">
                <v-btn color="grey"
                       variant="outlined"
                @click="clearAllCommands()">CLEAR ALL</v-btn>
            </v-sheet>

            <command-result-component v-for="c in commands" :key="c"
                :command="c"
            />
        </navigation-drawer-component>
    </v-sheet>
</template>


<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import NavigationDrawerComponent from "@/components/business/NavigationDrawerContainerComponent.vue";
import CommandFieldComponent from "@/components/business/shell/CommandFieldComponent.vue";
import CommandResultComponent from "@/components/business/shell/CommandResultComponent.vue";

@Component({
    components: {CommandResultComponent, CommandFieldComponent, NavigationDrawerComponent}
})
export default class ShellView extends Vue {

    private commands : string[] = [];

    private readonly ui= {
        command :''
    }

    // ---------------------------------------------------------------------------------------------------------------


    private clearAllCommands(){
        this.commands = [];
    }

}
</script>



<style scoped>

</style>