import Session from "@/Session";
import {Router} from "vue-router";
import {TokenService} from "@/services/TokenService";


export class ValidityAppMiddleware {

    private readonly service: TokenService;
    private readonly router: Router;


    constructor(service: TokenService, router: Router) {
        this.service = service;
        this.router = router;
    }

    async load(): Promise<void> {
        try {
            const isValid = await this.service.isValid(Session.getToken());
            if (!isValid) {
                Session.resetToken();
                await this.router.push({name: 'login'});
            }
            return Promise.resolve();
        } catch (e) {
            throw new Error(`#Validity app module error# Unable to check token validity : ${e}`);
        }
    }

}